<template>
	<div>
		test
	</div>
</template>

<script>
	export default {
		name: "Work"
	};
</script>

<style scoped>

</style>